import React from 'react'

const Error = () => {
    return (
        <div>
            <h1>Page is not found</h1>
        </div>
    )
}

export default Error